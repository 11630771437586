<template>
  <div id="app">
    <el-dialog title="登录/注册" center width="400px" :visible.sync="login.show">
      <el-form ref="form" :model="login.form" label-width="0px">
        <el-form-item>
          <el-input placeholder="账号" v-model="login.form.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="密码" v-model="login.form.code" type="code">
            <el-button slot="append" :disabled="Boolean(login.timer)" @click="handleSendCode">{{ login.timer ? `${login.count}s后重试` : '验证码' }}</el-button>
          </el-input>
        </el-form-item>
        <el-button class="login-button" type="primary" :loading="loading" @click="handleLogin">确认</el-button>
        <el-button type="text" style="margin: 0 auto;display: block;color: #999;text-decoration: underline;" @click="login.protocol = true">用户协议</el-button>
      </el-form>
    </el-dialog>
    <el-dialog title="用户协议" :visible.sync="login.protocol">
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3"
              style="font-size: 11pt;">提示条款</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">本协议是用户（以下简称“您”）与金斗云网站（网址：包括但不限于</span><span
          class="author-80329 font-size-3 url" style="font-size: 11pt;"><span
            style="font-size: 11pt;">jdscrm.com等</span></span><span class="author-80329 font-size-3"
          style="font-size: 11pt;">，简称“本站”）所有者（以下简称为“金斗云”）之间就金斗云网站服务等相关事宜所订立的契约。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。</span><span
          class="author-80329 b font-size-3" style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong>本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请您着重阅读。</strong></span></span>
      </p>
      <p><span class="author-80329 b font-size-3" style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong><br></strong></span></span>
      </p>
      <p><span class="author-80329 font-size-3"
          style="font-size: 11pt;">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与金斗云达成一致，成为金斗云平台“用户”。</span><span
          class="author-80329 b font-size-3 u" style="font-size: 11pt;"><span style="font-size: 11pt;"><strong><span
                style="text-decoration:underline;">阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</span>
            </strong>
          </span>
        </span>
      </p>
      <p><span class="author-80329 b font-size-3 u" style="font-size: 11pt;"><span style="font-size: 11pt;"><strong><span
                style="text-decoration:underline;"><br></span>
            </strong>
          </span>
        </span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第1条
              本站服务条款的确认和接纳</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.1
          本站的各项电子服务的所有权和运作权归金斗云所有。您同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。您确认：本协议条款是处理双方权利义务的依据，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定或约定。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.2
          您点击同意本协议的，即视为您确认自己具有享受本站服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.3 您确认，如果您在18周岁以下，您只能在父母或其他监护人的监护参与下才能使用本站。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.4 </span>金斗云<span
          class="author-80329 b font-size-3 i" style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong><em>保留在中华人民共和国大陆地区施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</em></strong></span></span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.5
          您使用本站提供的服务时，应同时接受适用于本站特定服务、活动等的准则、条款和协议（以下统称为“其他条款”）；如果以下使用条件与“其他条款”有不一致之处，则以“其他条款”为准。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">1.6为表述便利，商品和服务简称为“商品”或“货物”。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第2条
              本站服务</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">2.1
          金斗云通过互联网依法为您提供互联网信息等服务，您在完全同意本协议及本站相关规定的情况下，方有权使用本站的相关服务。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">2.2
          您必须自行准备如下设备和承担如下开支：（1）上网设备，包括并不限于手机、电脑或者其他上网终端、调制解调器及其他必备的上网装置；（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第3条
              用户信息收集及保护</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.1
          您应自行诚信向本站提供注册资料，您保证提供的注册资料真实、准确、完整、合法有效，您的注册资料如有变动的，应及时更新其注册资料。如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且金斗云保留单方终止您使用金斗云各项服务的权利。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.2
          您在本站进行注册、浏览、下单购物、评价、参加活动等行为时，涉及您真实姓名/名称、通信地址、联系电话、电子邮箱、订单详情、评价或反馈、投诉内容、cookies等信息的，本站有权从完成交易、提供配送、售后及客户服务、开展活动、完成良好的客户体验等多种角度予以收集，并将对其中涉及个人隐私信息予以严格保密，除非得到您的授权、为履行强行性法律义务（如国家安全机关指令）或法律另有规定、本注册协议或其他条款另有约定外，本站不会向外界披露您的隐私信息。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.3
          您注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定更改您的密码。您应谨慎合理的保存、使用您的账户信息。您若发现任何非法使用您的账户或其他存在安全漏洞的情况的，请立即通知本站并向公安机关报案。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.4
          您同意，金斗云拥有通过邮件、短信、电话、网站通知或声明等形式，向在本站注册、购物的用户、收货人发送订单信息、促销活动、售后服务、客户服务等告知信息的权利。如您不希望接收上述信息，可退订。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.5
          您同意：您选择向本站的商品销售商或服务提供商（以下统称为“销售商”，含金斗云及第三方卖家）提交订单购买商品或服务的，视为您向销售商披露个人相关信息，接受销售商向您提供商品销售、配送、售后服务、客户服务、处理信用卡付款、数据分析、市场营销帮助或其他必要事宜；如前述全部或部分事宜之一涉及由销售商外的第三方履行的，销售商有权将您的信息以必要方式向第三方披露，第三方有权以履行上述事宜为目的联系您。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.6
          您不得将在本站注册获得的账号、密码等账户信息提供给他人使用，否则您应承担由此产生的全部责任，并与实际使用人承担连带责任。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">3.7
          您同意，金斗云有权使用您的注册信息、用户名、密码等信息，登陆进入您的注册账户，进行证据保全，包括但不限于公证、见证、协助司法机关进行调查取证等。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第4条
              用户依法言行义务</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">本协议依据国家相关法律法规规章制定，您同意严格遵守以下义务：</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.1
          不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.2 从中国大陆向境外传输资料信息时必须符合中国有关法律法规；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.3 不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.4 不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.5
          不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.6 不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.7 不得教唆他人从事本条所禁止的行为；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.8
          除本注册协议、其他条款或另有其他约定外，您不得利用在本站注册的账户进行经营活动、牟利行为及其他未经本站许可的行为，包括但不限于以下行为：</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.8.1
          恶意利用技术手段或其他方式，为获取优惠、折扣或其他利益而注册账户、下单等行为，影响其他用户正常消费行为或相关合法权益、影响金斗云正常销售秩序的行为；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.8.2 发布广告、垃圾邮件；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.8.3 本站相关规则、政策、或网页活动规则中限制、禁止的行为；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.9
          您不得发布任何侵犯他人著作权、商标权等知识产权或其他合法权利的内容；如果有其他用户或权利人发现您发布的信息涉嫌知识产权、或其他合法权益争议的，这些用户或权利人有权要求金斗云删除您发布的信息，或者采取其他必要措施予以制止，金斗云将会依法采取这些措施。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.10 您应不时关注并遵守本站不时公布或修改的各类合法规则规定。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.11 本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知您的权利。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">4.12
          若您未遵守以上规定的，本站有权做出独立判断并采取暂停或关闭您的账号、冻结账号内余额及关闭相应交易订单、停止发货等措施。您须对自己在网上的言论和行为承担法律责任。</span>
      </p>
      <p><strong><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span></strong>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第5条
              商品信息</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 b font-size-3" style="font-size: 11pt;"><span style="font-size: 11pt;"><strong>5.1
            </strong></span></span><span class="author-80329 b font-size-3 i" style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong><em>本站上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本站不作特别通知。由于网站上商品信息的数量极其庞大，虽然本站会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；金斗云欢迎纠错，并会视情况给予纠错者一定的奖励。</em></strong></span></span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">5.2
          本站售后服务政策为本协议的组成部分，金斗云有权以声明、通知或其他形式变更售后服务政策。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第6条
              订单</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">6.1
          在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。</span>
      </p>
      <p><span class="author-80329 b font-size-3" style="font-size: 11pt;"><span style="font-size: 11pt;"><strong>6.2
            </strong></span></span><span class="author-80329 font-size-3"
          style="font-size: 11pt;">除法律另有强制性规定外，双方约定如下：</span><span class="author-80329 b font-size-3 i"
          style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong><em>本站上销售方展示的商品和价格等信息仅仅是交易信息的发布，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的交易诉求；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（
                以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了交易关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了交易关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立交易关系。您可以随时登录您在本站注册的账户，查询您的订单状态。</em></strong></span></span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">6.3
          尽管销售商做出最大的努力，但商品目录里的一小部分商品可能会有定价错误。如果发现错误定价，将采取下列之一措施，且不视为违约行为：</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">6.3.1
          如果某一商品的正确定价低于销售商的错误定价，销售商将按照较低的定价向您销售交付该商品。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">6.3.2
          如果某一商品的正确定价高于销售商的错误定价，销售商会通知您，并根据实际情况决定是否取消订单、停止发货、为已付款用户办理退款等。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">6.4
          由于市场变化及各种以合理商业努力难以控制的因素的影响，本站无法保证您提交的订单信息中希望购买的商品都会有货；如您下单所购买的商品，发生缺货，您有权取消订单，销售商亦有权取消订单，并为已付款的用户办理退款。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第7条
              配送</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">7.1 销售商将会把商品（货物）送到您所指定的收货地址。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">7.2 因如下情况造成订单延迟或无法配送、交货等，销售商不承担延迟配送、交货的责任：</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（1）您提供的信息错误、地址不详细等原因导致的；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（2）货物送达后无人签收，导致无法配送或延迟配送的；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（3）情势变更因素导致的；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（4）未能在本站所示的送货参考时间内送货的；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（5）因节假日、大型促销活动、店庆、预购或抢购人数众多等原因导致的；</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">（6）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第8条
              所有权及知识产权条款</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.1
          您一旦接受本协议，即表明您主动将其在任何时间段在本站发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给金斗云所有，您同意金斗云有权就任何主体侵权而单独提起诉讼。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.2
          本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于您在金斗云网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.3
          您同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其他主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.4
          金斗云有权不时地对本协议及本站的内容进行修改，并在本站张贴，无须另行通知您。在法律允许的最大限度范围内，金斗云对本协议及本站内容拥有解释权。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.5
          除法律另有强制性规定外，未经金斗云明确的书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，金斗云有权追究其法律责任。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">8.6
          本站所刊登的资料信息（包括但不限于文字、图表、商标、logo、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件等），均是金斗云或其内容提供者的财产，受中国和国际相关版权法规、公约等的保护，未经金斗云书面许可，任何第三方无权将上述资料信息复制、出版、发行、公开展示、编码、翻译、传输或散布至任何其他计算机、服务器、网站或其他媒介。本站上所有内容的汇编是金斗云的排他财产，受中国和国际版权法的保护。本站上所有软件都是金斗云或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。您不得鼓励、协助或授权任何其他人复制、修改、反向工程、反向编译或反汇编、拆解或者试图篡改全部或部分软件，或利用软件创设衍生产品。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第9条
              责任限制及不承诺担保</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.1
          除非另有明确的书面说明,本站及其所包含的或以其他方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.2
          除非另有明确的书面说明，金斗云不对本站的运营及其包含在本站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.3
          金斗云不担保本站所包含的或以其他方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.4
          如因不可抗力或其他本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，金斗云会合理地尽力协助处理善后事宜。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.5
          您应对账户信息及密码承担保密责任，因您未能尽到信息安全和保密责任而致使您的账户发生任何问题的，您应承担全部责任。同时，因网络环境存在众多不可预知因素，因您自身终端网络原因（包括但不限于断网、黑客攻击、病毒等）造成您的金斗云账户或个人信息等被第三方窃取的，金斗云不承担赔偿责任。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">9.6
          您了解并同意，金斗云有权应国家有关机关的要求，向其提供您在金斗云的用户信息和交易记录等必要信息。如您涉嫌侵犯他人合法权益，则金斗云有权在初步判断涉嫌侵权行为可能存在的情况下，向权利人提供您必要的个人信息。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第10条
              协议更新及用户关注义务</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3"
          style="font-size: 11pt;">根据国家法律法规变化及网站运营需要，金斗云有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。您可随时登陆查阅最新协议；</span><span
          class="author-80329 b font-size-3 i" style="font-size: 11pt;"><span
            style="font-size: 11pt;"><strong><em>您有义务不时关注并阅读最新版的协议、其他条款及网站公告。如您不同意更新后的协议，可以且应立即停止接受金斗云网站依据本协议提供的服务；如您继续使用本站提供的服务的，即视为同意更新后的协议。金斗云建议您在使用本站之前阅读本协议及本站的公告。</em></strong></span></span>
        <span class="author-80329 font-size-3"
          style="font-size: 11pt;">如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</span>
      </p>
      <p><strong><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span></strong>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第11条
              法律管辖和适用</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3"
          style="font-size: 11pt;">本协议的订立、执行和解释及争议的解决均适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其他条款继续有效。如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;"><br></span>
      </p>
      <p>
        <inherit style="display: block;"><strong><span class="author-80329 b font-size-3" style="font-size: 11pt;">第12条
              其他</span></strong>
        </inherit>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">12.1 金斗云网站所有者是指在政府部门依法许可或备案的金斗云网站经营主体。</span>
      </p>
      <p><span class="author-80329 font-size-3" style="font-size: 11pt;">12.2
          金斗云尊重您的合法权利，本协议及本站上发布的各类规则、声明、售后服务政策等其他内容，均是为了更好的、更加便利的为您提供服务。本站欢迎您和社会各界提出意见和建议，金斗云将虚心接受并适时修改本协议及本站上的各类规则。</span>
      </p>
      <p>
        <br>
      </p>
    </el-dialog>
    <el-drawer
      title="购物车"
      :visible.sync="cart.show"
      direction="rtl">
      <div class="cart-popup" v-if="auth && cart.list.length > 0">
        <div class="cart-list">
          <div class="cart-item" v-for="(item, index) in cart.list" :key="index">
            <div class="item-image" :style="{ backgroundImage: `url(${domain}${item.goods_pre}!/fw/400/compress/true/unsharp/true)` }">
              <div class="item-delete" @click="handleDeleteGoods(item.cart_id, index)">删除</div>
            </div>
            <div class="item-content">
              <div class="item-title">{{ item.goods_desc }}</div>
              <div class="item-price">
                <div class="price-text">￥{{ item.goods_price }}</div>
                <div>x{{ item.cart_num }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-empty v-else-if="auth && cart.list.length === 0"></el-empty>
      <el-empty description="您未登录" v-else>
        <el-button type="primary" @click="handleShowLogin">登录/注册</el-button>
      </el-empty>
    </el-drawer>
    <el-dialog title="商品详情" center width="500px" :visible.sync="goods.show">
      <div class="goods-popup" v-if="goods.data">
        <el-carousel class="app-swiper" height="400px">
          <el-carousel-item v-for="(item, index) in goods.data.goods_pre" :key="index">
            <div class="swiper-item" :style="{ backgroundImage: `url(${domain}${item.goods_pic}!/fw/600/compress/true/unsharp/true)` }"></div>
          </el-carousel-item>
        </el-carousel>
        <div class="goods-price" v-if="goods.data.texture_list">
          <div class="price-content">￥{{goods.data.texture_list[0].price}}</div>
          <i class="el-icon-shopping-cart-2" title="加入购物车" @click="handleAddCart"></i>
        </div>
        <div class="goods-title">{{ goods.data.goods_desc }}</div>
        <div class="goods-content" v-if="goods.data.content_html.length > 0">
          <img v-for="(item, index) in goods.data.content_html" :key="index" :src="`${domain}${item.img_src}`" alt="">
        </div>
      </div>
    </el-dialog>
    <div class="app-top">
      <div class="top-content">
        <el-popover
          placement="bottom"
          trigger="hover"
          v-if="auth">
          <el-button type="text" style="width: 100%;" @click="handleLogout">退出登录</el-button>
          <div class="top-button" slot="reference">您好！{{ auth.phone }}</div>
        </el-popover>
        <!-- <div class="top-button" v-if="auth">您好！{{ auth.phone }}</div> -->
        <div class="top-button" @click="login.show = true" v-else>登录/注册</div>
        <div class="top-button" @click="login.protocol = true">用户协议</div>
        <el-link class="top-button" href="https://jinshuju.net/f/TP3rhr">商家入驻</el-link>
      </div>
    </div>
    <div class="app-header">
      <div class="header-logo"></div>
      <div class="header-content">
        <el-input class="header-search" placeholder="请输入关键词" prefix-icon="el-icon-search" clearable v-model="keyword">
          <el-button slot="append" @click="handleSearch">搜索</el-button>
        </el-input>
        <el-badge :value="cart.list.length" :hidden="cart.list.length === 0">
          <el-button round icon="el-icon-shopping-cart-full" type="primary" plain @click="handleShowCart">购物车</el-button>
        </el-badge>
      </div>
    </div>
    <div class="app-nav">
      <div
        class="nav-item"
        v-for="(item, index) in NAV"
        :key="index"
        :class="[item.value === activeNav ? 'active' : '']"
        @click="handleChangeNav(item.value, index)"
      >{{ item.label }}</div>
    </div>
    <div class="app-scroll">
      <div class="scroll-content">
        <div v-show="activeTab === 1">
          <el-carousel class="app-swiper" height="450px">
            <el-carousel-item v-for="(item, index) in swiper" :key="index">
              <div class="swiper-item" :style="{ backgroundImage: `url(${domain}${item.pic}!/fw/1000/compress/true/unsharp/true)` }"></div>
            </el-carousel-item>
          </el-carousel>
          <div class="app-block" v-for="(subject, row) in recommend" :key="row">
            <div class="block-title">{{subject.title}}</div>
            <div class="block-bg" v-if="subject.pic" :style="{ backgroundImage: `url(${domain}${subject.pic}!/fw/1000/compress/true/unsharp/true)` }"></div>
            <div class="block-goods">
              <div class="app-goods" v-for="(goods, col) in subject.ralate_goods" :key="col" @click="handleViewGoods(goods.id)">
                <div class="item-image box" :style="{ backgroundImage: `url(${domain}${goods.goods_pre}!/fw/250/compress/true/unsharp/true)` }"></div>
                <div class="item-title">{{goods.goods_desc}}</div>
                <div class="item-price">￥{{goods.goods_price}}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="activeTab === 2">
          <div class="app-goods" v-for="(goods, index) in list" :key="index" @click="handleViewGoods(goods.id)">
            <div class="item-image box" :style="{ backgroundImage: `url(${domain}${goods.goods_pre}!/fw/250/compress/true/unsharp/true)` }"></div>
            <div class="item-title">{{goods.goods_desc}}</div>
            <div class="item-price">￥{{goods.goods_price}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer">2022-2023 武汉宝谷之宝网络科技有限公司 鄂ICP备15005445号-6</div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    const auth = this.$cookie.get('auth') ? JSON.parse(this.$cookie.get('auth')) : null
    return {
      NAV: [
        { label: '首页', value: '' },
        { label: '家居', value: '家居' },
        { label: '厨房', value: '厨房' },
        { label: '家电', value: '家电' },
        { label: '办公', value: '办公' },
        { label: '数码', value: '数码' },
        { label: '彩宝', value: '彩宝' },
        { label: 'K金', value: '金750' },
      ],
      domain: 'https://file-cdn.2bao.org',
      loading: false,
      swiper: [],
      recommend: [],
      keyword: '',
      activeNav: '',
      auth,
      login: {
        show: false,
        timer: null,
        count: 60,
        form: {
          phone: '',
          code: ''
        },
        protocol: false
      },
      cart: {
        show: false,
        list: []
      },
      goods: {
        show: false,
        data: null
      },
      activeTab: 1,
      list: []
    }
  },
  methods: {
    request (url, data = {}) {
      const { token, user_id } = this.auth || {}
      return new Promise(resolve => {
        this.$jquery.ajax({
          type: 'POST',
          url: `https://api.buyer.2bao.org/v1.1${url}`,
          data: token ? { token, user_id, ...data } : data,
          success: (res) => {
            if (res?.status) {
              // console.log(res)
              resolve(res)
            } else {
              if (res?.code === 10004) {
                this.handleLogout()
              }
              resolve(null)
              this.$notify({
                title: '警告',
                message: res?.msg?.zh || '请求出错，请重试',
                type: 'warning'
              })
            }
          },
          error: () => {
            resolve(null)
            this.$notify({
              title: '警告',
              message: '请求出错，请重试',
              type: 'warning'
            })
          }
        })
      })
    },
    handleChangeNav (val, index) {
      this.activeNav = val
      if (index === 0) {
        this.activeTab = 1
        return
      }
      this.searchGoods({ texture: val })
    },
    handleShowLogin () {
      this.cart.show = false
      setTimeout(() => {
        this.login.show = true
      }, 300)
    },
    handleSendCode () {
      const { phone } = this.login.form
      if (!/^(0|86|17951)?1[3-9][0-9][0-9]{8}$/.test(phone)) {
        this.$notify({
          title: '警告',
          message: '请输入正确的账号',
          type: 'warning'
        })
        return
      }
      this.login.count = 60
      this.login.timer = setInterval(() => {
        this.login.count--
        if (this.login.count === 0) {
          clearInterval(this.login.timer)
          this.login.timer = null
          this.login.count = 60
        }
      }, 1000)
      this.request('/sendcode', { type: 3, phone })
    },
    async handleLogin () {
      const { phone, code } = this.login.form
      if (!/^(0|86|17951)?1[3-9][0-9][0-9]{8}$/.test(phone)) {
        this.$notify({
          title: '警告',
          message: '请输入正确的账号',
          type: 'warning'
        })
        return
      }
      if (!/^[0-9]{6}$/.test(code)) {
        this.$notify({
          title: '警告',
          message: '请输入正确的验证码',
          type: 'warning'
        })
        return
      }
      this.loading = true
      const res = await this.request('/login', { phone, code })
      this.loading = false
      if (res) {
        this.$cookie.set('auth', JSON.stringify(res.data))
        this.auth = res.data
        this.login.show = false
        this.initCart()
      }
    },
    handleLogout () {
      this.$cookie.remove('auth')
      this.auth = null
    },
    async handleViewGoods (goods_id) {
      const res = await this.request('/goods', { client: '2', goods_id })
      if (res) {
        res.data.goods_info.content_html = res.data.goods_info.content_html ? JSON.parse(res.data.goods_info.content_html) : []
        this.goods.data = res.data.goods_info
        this.goods.show = true
      }
    },
    handleSearch () {
      if (this.keyword) {
        this.searchGoods({ goods_name: this.keyword })
      }
    },
    async searchGoods (obj) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      })
      const res = await this.request('/search', { ...obj, page: 1, type: 1 })
      loading.close()
      if (res) {
        this.list = res.data.list
        this.activeTab = 2
      }
    },
    async initCart () {
      if (this.auth) {
        const res = await this.request('/listcart')
        if (Array.isArray(res?.data)) {
          this.cart.list = res.data
        }
      }
    },
    async handleShowCart () {
      this.initCart()
      this.cart.show = true
    },
    async handleAddCart () {
      if (!this.auth) {
        this.goods.show = false
        setTimeout(() => {
          this.login.show = true
        }, 300)
        return
      }
      if (this.loading) return
      this.loading = true
      const { id, texture_list } = this.goods.data
      const res = await this.request('/addcart', { goods_id: id, texture_id: texture_list?.[0]?.id, cart_num: 1 })
      this.loading = false
      if (res) {
        this.initCart()
        this.$notify({
          title: '提示',
          message: '加入成功',
          type: 'success'
        })
      }
    },
    async handleDeleteGoods (cart_id, index) {
      if (this.loading) return
      this.loading = true
      const res = await this.request('/deletecart', { dele: cart_id })
      this.loading = false
      if (res) {
        this.cart.list.splice(index, 1)
      }
    }
  },
  async mounted () {
    const res = await this.request('/home', { client: '2' })
    if (res) {
      this.swiper = res.data.roll
      this.recommend = [...res.data.new, ...res.data.limit, ...res.data.recomd]
    }
    this.initCart()
  }
}
</script>

<style lang="less"></style>
